@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

$break-laptop: 1152px;
$break-desktop: 1352px;

.arc-reporting-form-page {
    margin-top: 40px;

    .form-container {
        padding-left: 40px;
        padding-right: 24px;

        @media (max-width: $break-laptop) {
            width: -webkit-fill-available;
        }

        @media (min-width: $break-laptop) and (max-width: $break-desktop) {
            width: 836px;
        }

        @media (min-width: $break-desktop) {
            width: 836px;
            margin-left: 8%;
        }
    }

    .form-header {
        font-weight: bold;
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 24px;
    }

    .navigation {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-bottom: 5px;
    }
    .wrapper-sidebar-left {
        width: 224px;
    }

    .sidebar-left {
        max-width: 210px;
        position: fixed;
        left: 0;
        padding-left: 24px;

        @media (max-width: $break-laptop) {
            margin-left: 0;
        }
        @media (min-width: $break-laptop) and (max-width: $break-desktop) {
            margin-left: 3.8%;
        }
        @media (min-width: $break-desktop) {
            margin-left: 10.9%;
        }
        .fas,
        .far {
            margin-right: 5px;

            &.fa-check-circle, &.fa-exclamation-triangle {
                color: $brand-primary;
            }

            &.fa-circle {
                color: #cccccc;
            }
        }

        li {
            list-style: none;
            cursor: pointer;
            div {
                border-bottom: 1px solid #cccccc;
                padding: 6px;
                font-size: 13px;

                &:hover {
                    background-color: rgba(81, 52, 201, 0.1);
                }

                &.selected {
                    color: $brand-primary;
                    font-weight: bold;
                }
            }
        }
    }

    .sidebar-right {
        position: fixed;
        right: 0;
    }

    .expandable-panel {
        border: 0;

        .panel-body,
        .panel-heading {
            background-color: rgba(216, 96, 24, 0.08);
        }

        .panel-heading {
            font-weight: bold;

            .fas,
            .far {
                margin-right: 5px;

                &.fa-check-circle, &.fa-exclamation-triangle {
                    color: $brand-primary;
                }

                &.fa-circle {
                    color: #cccccc;
                }
            }

            .expand-button {
                cursor: pointer;
                font-weight: bold;
                font-size: 20px;
                color: $brand-primary;
                line-height: 10px;
                margin: -10px -15px;
                padding: 15px;
            }
        }

        .panel-body {
            padding: 15px;
            transition: 0.3s;

            &.collapsed {
                transition-property: overflow;
                transition-duration: 0.3s;
                max-height: 0;
                padding: 0 15px;
                overflow: hidden;
            }
        }
    }

    .radio-option {
        margin-right: 50px;

        input {
            margin-right: 10px;
            vertical-align: top;
            margin-top: 4px;
        }
    }

    .select {
        > div {
            min-height: 34px;
        }
    }

    label + button {
        display: block;
    }

    .add-item {
        margin-left: 7px;
    }

    .error {
        border-color: red;
    }

    h4 {
        font-size: 18px;
        font-weight: bold;
        color: black;
        border-bottom: 2px solid #eee;
        padding-bottom: 6px;
        text-transform: uppercase;

        .fa {
            float: right;
            font-size: 14px;
            margin-top: 4px;
            color: #888;
            cursor: pointer;

            &:hover {
                color: black;
            }
        }
    }

    .diagram-container {
        background: #fafafa;
        border: 1px solid #999999;
        border-radius: 4px;
        width: 100%;
        height: 250px;

        img {
            width: 100%;
            height: 100%;
            padding: 20px;
        }
    }

    .btn.btn-default {
        background: transparent;
        width: 220px;
    }

    .claim-party > div > div:last-child {
        /* Make the dropdown menu relative to avoid being hidden by the panel container */
        position: relative;
    }

    .amendment-description {
        font-size: 13px;

        .fa {
            float: left;
            margin-top: 3px;
            margin-right: 10px;
            margin-bottom: 5px;
            color: #00848e;
        }
    }

    .add-amendment {
        background-color: $brand-primary;
        width: 100%;
    }

    .history-item {
        .date {
            color: #333;
        }

        .title {
            font-weight: bold;
        }

        .document {
            color: $brand-primary;
            margin: 5px 0;

            .fa {
                margin-right: 10px;
            }
        }

        ul {
            margin: 0;
            padding-left: 15px;
            font-size: 13px;
        }
    }

    .testing {
        &:after {
            position: relative;
            display: block;
            z-index: 0;
            top: 100%;
            margin: 5px 0 0 5px;
            content: "\25cf\2004 Changed";
            color: #006064;
        }
    }

    .has-changed {
        color: #006064;
        font-size: 12px;
        margin: 5px 0 0 2px;

        &:before {
            display: inline-block;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #006064;
            margin-right: 5px;
        }
    }
}

.hidden {
    display: none;
}

.arc-error {
    color: red;
}

.mandatory-field:after {
    content: "*";
    padding-left: 2px;
}

@media (min-width: 1200px) {
    #agent-form-page .sidebar-right {
        margin-left: 8.33333333%;
    }
}

.upload-trim-modal {
    .upload-button {
        position: relative;

        input[type="file"],
        .fa {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        input[type="file"] {
            position: absolute;
            cursor: pointer;
            opacity: 0;
        }

        .fa {
            line-height: 100px;
            border: 1.6px dashed #ccc;
            color: $brand-primary;
            font-size: 40px;
            text-align: center;
            pointer-events: none;
            border-radius: 4px;
            transition: font-size 0.3s;
        }

        &:hover .fa {
            font-size: 50px;
        }
    }

    .video-container {
        position: relative;

        video {
            width: 100%;
            height: auto;
        }

        .mask {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: white;

            .fa {
                position: absolute;
                font-size: 50px;
                top: 50%;
                left: 50%;
                margin-top: -25px;
                margin-left: -25px;
            }
        }
    }

    .trim {
        position: relative;
        height: 80px;
        width: 100%;
        margin-top: 20px;

        > img {
            float: left;
            width: 12.5%;
            height: 100%;
            user-select: none;
            object-fit: cover;
        }

        .start-mask,
        .end-mask {
            position: absolute;
            top: 0;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.4);
            pointer-events: none;
        }

        .start-mask,
        .start-cursor {
            left: 0;
        }

        .end-mask,
        .end-cursor {
            right: 0;
        }

        .start-cursor,
        .end-cursor {
            position: absolute;
            top: 0;
            height: 100%;
            width: 3px;
            background-color: $brand-primary;
            cursor: pointer;

            img {
                position: absolute;
                bottom: 100%;
                left: -5.5px;
                margin-bottom: -4px;
            }
        }
    }
}
