.date-input {
    .SingleDatePicker {
        font-family: inherit;
        display: block;

        .SingleDatePickerInput {
            display: block;
            border: none;
            margin: 0.25rem 0;
            
            .CalendarMonth_caption {
                padding-bottom: 50px;
            }
            .DateInput {
                width: 100%;

                input {
                    font-weight: inherit;
                    font-size: inherit;
                    padding: 0.5rem 1rem;
                    border: 1px solid #bdbdbd;
                    border-radius: 4px;
                    line-height: inherit;
                    padding-right: 20px;
                    height: 47px;
                }

                &::after {
                    content: "\f073";
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: $brand-primary;
                }
            }
        }

        .DateInput_fang {
            margin-top: -14px;
        }

        .SingleDatePicker_picker {
            margin-top: -15px;
        }

        .DayPickerKeyboardShortcuts_buttonReset {
            display: none;
        }
    }

    &.date-input-error {
        .SingleDatePicker .SingleDatePickerInput .DateInput input {
            background-color: rgba(198, 40, 40, 0.08);
            border-color: #C62828;
        }
    }
}

.date-input-filter {
    .SingleDatePicker {
        padding-bottom: 10px;
        font-family: inherit;
        display: block; 

        .SingleDatePickerInput {
            display: block;
            border: none;
            margin-top: 4px;
            .CalendarMonth_caption {
                padding-bottom: 50px;
            }
            .DateInput {
                width: fit-content;
                display: block;
                input {
                    font-weight: inherit;
                    font-size: inherit;
                    padding: 0.5rem 1rem;
                    border: 1px solid #bdbdbd;
                    border-radius: 4px;
                    line-height: inherit;
                    padding-right: 20px;
                    height: 47px;
                }

                &::after {
                    content: "\f073";
                    font-family: "Font Awesome 5 Free";
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    color: $brand-primary;
                }
            }
        }

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover {
            background: #d86018;
            border: 1px double #d86018;
        }

        .DateInput_fang {
            display: none;
        }

        .SingleDatePicker_picker {
            position: initial;
        }

        .SingleDatePicker_arrow {
            display: none;
        }

        .DayPickerKeyboardShortcuts_buttonReset {
            display: none;
        }
    }
}
