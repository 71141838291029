.phone-input {
    .react-tel-input {
        height: 47px;
        margin: 0.25rem 0;

        .form-control {
            width: 100%;
            box-shadow: none;
            border-radius: 4px;
            border-color: #bdbdbd;

            &:hover {
                box-shadow: none;
            }

            &:disabled {
                background-color: #E0E0E0;
            }
        }
        input {
            height: 47px;
        }
    }

    &.phone-input-error {
        .react-tel-input .form-control {
            background-color: rgba(198, 40, 40, 0.08);
            border-color: #C62828;
        }
    }
}
