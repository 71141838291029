#arc-reporting-submitted-page {
    margin-top: 10vh;

    > div {
        border: 1px solid #CCC;
        padding: 15px 20px;
    }

    h2,
    h3 {
        font-weight: bold;
        color: black;
        margin: 5px 0;
    }

    .uppercase {
        text-transform: uppercase;
    }

    .fa-info-circle {
        color: $brand-primary;
    }

    .btn {
        width: 48%;
        margin-top: 10px;
    }

    .btn.btn-default {
        background-color: transparent;
        margin-right: 4%;
    }
}